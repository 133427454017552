<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/manage-product" class="btn btn-wz-tab">Manage Products</router-link>
    <router-link to="/add-product" class="btn btn-wz-tab">Add Products</router-link>
    <router-link :to="$route.params.id ? '/edit-product/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Edit Product</router-link>
    <router-link :to="$route.params.id ? '/detail-product/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Detail Product</router-link>
    <router-link to="/review-product" class="btn btn-wz-tab">Product Review</router-link>
  </div>
</template>
<script>
export default {
  name: "ProductListNavTab",
}
</script>
