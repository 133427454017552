<template>
  <div class="container-fluid">
    <MobileGuide />
    <ProductListNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-auto">
          <h2 class="roboto subtitle bold">Manage Products</h2>
        </div>
        <div class="col d-flex">
          <button type="button" class="btn btn-wz-export ml-auto text-nowrap overflow-hidden" @click="exportCsv()">Export</button>
          <form
            @submit.prevent="searchProduct"
            class="input-search flex-grow-1 flex-lg-grow-0"
          >
            <input
              id="search"
              type="text"
              placeholder="Search Product Name"
              class="form-control"
            />
            <button class="btn"><font-awesome icon="search" /></button>
          </form>
        </div>
      </div>
      <b-tabs fill>
        <b-tab title="All" class="pt-5" @click="currentTab = 'all'">
          <ProductListTable
            v-model="listAll.selected"
            :list="listAll"
            @bulkEdit="formBulkEdit.product_ids = $event"
            @selected="listAll.selected = $event"
            @changeActive="refreshList()"
            @changeCurrentPage="getProductList('all', listAll.limit, $event, search)"
            @changePerPage="getProductList('all', $event, 1, search)"
            @deleteProduct="selectedProduct = $event"
          />
        </b-tab>
        <b-tab title="In Stock" class="pt-5" @click="currentTab = 'in_stock'">
          <ProductListTable
            v-model="listInstock.selected"
            :list="listInstock"
            @bulkEdit="formBulkEdit.product_ids = $event"
            @selected="listInstock.selected = $event"
            @changeActive="refreshList()"
            @changeCurrentPage="getProductList('in_stock', listInstock.limit, $event, search)"
            @changePerPage="getProductList('in_stock', $event, 1, search)"
            @deleteProduct="selectedProduct = $event"
          />
        </b-tab>
        <b-tab title="Inactive" class="pt-5" @click="currentTab = 'inactive'">
          <ProductListTable
            v-model="listInactive.selected"
            :list="listInactive"
            @bulkEdit="formBulkEdit.product_ids = $event"
            @selected="listInactive.selected = $event"
            @changeActive="refreshList()"
            @changeCurrentPage="getProductList('inactive', listInactive.limit, $event, search)"
            @changePerPage="getProductList('inactive', $event, 1, search)"
            @deleteProduct="selectedProduct = $event"
          />
        </b-tab>
        <!-- <b-tab title="Rejected" class="pt-5" @click="currentTab = 'rejected'">
          <ProductListTable @changeActive="refreshList()" @deleteProduct="selectedProduct = $event" :list="listRejected" />
          <b-pagination
            class="float-right"
            v-model="listRejected.page"
            :total-rows="listRejected.total"
            :per-page="listRejected.limit"
            @change="getProductList('rejected', listRejected.limit, $event, search)"
          />
          <select
            v-model="listRejected.limit"
            class="form-control w-auto float-right"
            @input="getProductList('rejected', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab> -->
        <b-tab title="Out of Stock" class="pt-5" @click="currentTab = 'out_stock'">
          <ProductListTable
            v-model="listOutstock.selected"
            :list="listOutstock"
            @bulkEdit="formBulkEdit.product_ids = $event"
            @selected="listOutstock.selected = $event"
            @changeActive="refreshList()"
            @changeCurrentPage="getProductList('out_stock', listOutstock.limit, $event, search)"
            @changePerPage="getProductList('out_stock', $event, 1, search)"
            @deleteProduct="selectedProduct = $event"
          />
        </b-tab>
      </b-tabs>
    </div>
    <b-modal id="modal-bulk-edit-product" hide-footer centered scrollable @hidden="formBulkEdit.product_ids = []">
      <template #modal-title>
        Bulk Edit Products
        <!-- <h4 class="roboto">Bulk Edit Products</h4> -->
      </template>
      <form @submit.prevent="bulkEditProduct" class="row align-items-center">
        <div class="col-md-4 mb-2">
          <label class="m-0" for="select_brand">
            Select Brand <input type="checkbox" v-model="bulkEditBrand" @input="getBrands">
          </label>
        </div>
        <div class="col-md-8 mb-2 d-flex align-items-center">
          <InputSelect
            v-model="selectedBrand"
            class="w-100 mb-0"
            placeholder="Select a brand"
            :options="brands"
            :unhideFirstOpt="true"
            :disabledFirstOpt="true"
            :disabled="!bulkEditBrand"
          />
          <div v-if="bulkEditBrand && !brands.length" class="pl-2">
            <fawesome-pro icon="spinner" spin />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <label class="m-0" for="price">Price</label>
        </div>
        <div class="col-md-8 mb-2">
          <InputText v-model="$v.formBulkEdit.price.$model" type="number" min="null" placeholder="Price" />
        </div>
        <div class="col-md-4 mb-2">
          <label class="m-0" for="price_relative">Price Relative</label>
        </div>
        <div class="col-md-8 mb-2">
          <b-form-checkbox v-model="$v.formBulkEdit.price_relative.$model" switch size="lg" />
        </div>
        <div class="col-md-4 mb-2">
          <label class="m-0" for="discount_type">Discount Type</label>
        </div>
        <div class="col-md-8 mb-2">
          <InputSelect v-model="$v.formBulkEdit.discount_type.$model" class="mb-0" value="0" :options="[
              { name: 'No Discount', id: 0 },
              { name: 'Nominal', id: 1 },
              { name: 'Percentage', id: 2 },
            ]"
          />
        </div>
        <div class="col-md-4 mb-2">
          <label class="m-0" for="discount">Discount</label>
        </div>
        <div class="col-md-8 mb-2">
          <InputText v-model="$v.formBulkEdit.discount.$model" type="number" placeholder="Discount" :disabled="formBulkEdit.discount_type == 0" />
        </div>
        <div class="col-md-12 mb-2">
          Effective Date
          <div class="d-flex">
            <InputDate v-model="$v.formBulkEdit.discount_active_from.$model" :min="new Date()" :disabled="formBulkEdit.discount_type == 0" />
            <div class="bg-light px-2 pt-2 border">~</div>
            <InputDate v-model="$v.formBulkEdit.discount_active_to.$model" :min="new Date()" :disabled="!formBulkEdit.discount_active_from" />
          </div>
          <div class="d-flex">
            <InputText v-model="$v.formBulkEdit.discount_active_from_time.$model" type="time" class="w-100" :disabled="!formBulkEdit.discount_active_from" />
            <div class="bg-light pl-2 pr-3 pt-2 border">~</div>
            <InputText v-model="$v.formBulkEdit.discount_active_to_time.$model" type="time" class="w-100" :disabled="!formBulkEdit.discount_active_from_time" />
          </div>
        </div>
        <div class="w-100"><hr></div>
        <div class="ml-auto col-auto">
          <button type="button" class="btn btn-danger mr-2" @click="$bvModal.hide('modal-bulk-edit-product')">Cancel</button>
          <input type="submit" class="btn btn-primary" value="Submit">
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-delete-product"
      hide-header
      centered
      @ok="deleteProduct(selectedProduct)"
    >
      <p class="text-center m-0">
        <b v-if="selectedProduct">{{ selectedProduct.name }}</b><br/>
        Are you sure you want to delete this product?
      </p>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import ProductListTable from "@/components/product/ProductListTable";
import ProductListNavTab from '@/components/product/ProductListNavTab';
import MobileGuide from "@/components/navs/MobileGuide";
import InputText from "@/components/formCustom/InputText";
import InputSelect from "@/components/formCustom/InputSelect";
import InputDate from '../../../components/formCustom/InputDate.vue';
import { required, requiredIf, decimal, minValue, maxValue } from "vuelidate/lib/validators";

import moment from "moment";
const XLSX = require("xlsx");

export default {
  name: "ProductList",
  components: {
    MobileGuide,
    ProductListNavTab,
    ProductListTable,
    InputText,
    InputSelect,
    InputDate,
  },
  data() {
    return {
      selectedProduct: null,
      currentTab: "all",
      search: null,

      listAll: {
        total: 0,
        list: [],
        items: [],
        limit: 10,
        page: 1,
        busy: false,
        selected: [],
      },
      listInstock: {
        total: 0,
        list: [],
        items: [],
        limit: 10,
        page: 1,
        busy: false,
        selected: [],
      },
      listOutstock: {
        total: 0,
        list: [],
        items: [],
        limit: 10,
        page: 1,
        busy: false,
        selected: [],
      },
      listInactive: {
        total: 0,
        list: [],
        items: [],
        limit: 10,
        page: 1,
        busy: false,
        selected: [],
      },
      listApprovalPending: {
        total: 0,
        list: [],
        items: [],
        limit: 10,
        page: 1,
        busy: false,
        selected: [],
      },
      // listRejected: {
      //   total: 0,
      //   items: [],
      //   limit: 10,
      //   page: 1,
      //   busy: false,
      //   selected: [],
      // },
      bulkEditBrand: false,
      selectedBrand: null,
      brands: [],
      formBulkEdit: {
        product_ids: [],
        price: 0,
        price_relative: true,
        discount_type: 0,
        discount: null,
        discount_active_from: null,
        discount_active_to: null,
        discount_active_from_time: null,
        discount_active_to_time: null,
      }
    };
  },
  validations() {
    return {
      selectedBrand: { required: requiredIf(() => this.bulkEditBrand) },
      formBulkEdit: {
        product_ids: { required: requiredIf(() => !this.bulkEditBrand) },
        price: { required, decimal },
        price_relative: { required },
        discount_type: { required, decimal },
        discount: {
          required: requiredIf(() => this.formBulkEdit.discount_type != 0),
          minValue: minValue(0),
          maxValue: maxValue(this.formBulkEdit.discount_type == 2 ? 100 : Infinity),
          decimal,
        },
        discount_active_from: {},
        discount_active_to: {
          required: requiredIf(() => this.formBulkEdit.discount_active_from),
        },
        discount_active_from_time: {
          required: {},
        },
        discount_active_to_time: {
          required: requiredIf(() => this.formBulkEdit.discount_active_from_time),
        },
      }
    }
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.getProductList("all", this.listAll.limit, 1, this.search);
      this.getProductList("in_stock", this.listInstock.limit, 1, this.search);
      this.getProductList("out_stock", this.listOutstock.limit, 1, this.search);
      this.getProductList("inactive", this.listInactive.limit, 1, this.search);
      this.getProductList("approval_pending", this.listApprovalPending.limit, 1, this.search);
      // this.getProductList("rejected", this.listRejected.limit, 1, this.search);
    },
    searchProduct() {
      const el = document.getElementById("search");
      this.search = el.value;
      this.refreshList()
    },
    async bulkEditProduct() {
      this.$v.$touch()
      if (this.$v.$error) {
        toaster.make('Please fill in the form correctly', 'danger')
        console.error(Object.keys(this.$v.formBulkEdit).reduce((obj, key) => {
          if (key.indexOf("$") < 0 && this.$v.formBulkEdit[key].$invalid) 
            obj[key] = this.$v.formBulkEdit[key]
          return obj
        }, {}))
        return
      }
      try {
        let data = JSON.parse(JSON.stringify(this.formBulkEdit))
        if (data.discount_active_from)
          data.discount_active_from += " " + (data.discount_active_from_time ? data.discount_active_from_time : "00:00") + ":00"
        if (data.discount_active_to)
          data.discount_active_to += " " + (data.discount_active_to_time ? data.discount_active_to_time : "00:00") + ":00"
        data.discount_type = parseInt(data.discount_type)
        if (data.discount_type == 0) delete data.discount
        delete data.discount_active_from_time
        delete data.discount_active_to_time

        const res = await this.$api.product.bulkEditProduct(data, {
          ...this.bulkEditBrand ? { brand_id: this.selectedBrand } : {}
        })
        if (res.status === 200) {
          toaster.make(res.data.message, 'success')
          this.$bvModal.hide('modal-bulk-edit-product')
          this.refreshList()
          this.$v.$reset();
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getBrands() {
      if (this.brands.length) return
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) this.brands = res.data.data.map(({ brand }) => ({ name: brand.name, id: brand.id }))
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getProductList(type, limit, page, query) {
      try {
        const res = await this.$api.product.getProductList(
          type,
          limit,
          page - 1,
          query
        );
        if (res.status === 200) {
          const d = res.data;
          switch (type) {
            case "all":
              this.listAll.total = d.meta.total;
              this.listAll.items = d.data;
              this.listAll.page = page;
              this.listAll.limit = limit;
              break;
            case "in_stock":
              this.listInstock.total = d.meta.total;
              this.listInstock.items = d.data;
              this.listInstock.page = page;
              this.listInstock.limit = limit;
              break;
            case "out_stock":
              this.listOutstock.total = d.meta.total;
              this.listOutstock.items = d.data;
              this.listOutstock.page = page;
              this.listOutstock.limit = limit;
              break;
            case "inactive":
              this.listInactive.total = d.meta.total;
              this.listInactive.items = d.data;
              this.listInactive.page = page;
              this.listInactive.limit = limit;
              break;
            case "approval_pending":
              this.listApprovalPending.total = d.meta.total;
              this.listApprovalPending.items = d.data;
              this.listApprovalPending.page = page;
              this.listApprovalPending.limit = limit;
              break;
            // case "rejected":
            //   this.listRejected.total = d.meta.total;
            //   this.listRejected.items = d.data;
            //   this.listRejected.page = page;
            //   break;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteProduct(product) {
      try {
        const res = await this.$api.product.deleteProduct(product.id)
        if (res.status === 200) {
          toaster.make(res.data.message, "success")
          this.refreshList()
        }
      } catch (e) {
        console.error(e);
      }
    },
    async exportCsv() {
      try {
        const res = await this.$api.product.getProductList(this.currentTab, 1000, 0, this.search)
        if (res.status === 200) {
          const arrData = res.data.data.map(el => ({
              name: el.name,
              sku: el.sku,
              created_date: moment(String(el.created_date)).format('YYYY-MM-DD HH:mm:ss').toString(),
              price: el.price,
              promo_price: el.promo_price,
              fee_price: el.fee_price,
              stock: el.stock,
              is_active: el.is_active
            }))
          // https://lifesaver.codes/answer/how-to-simply-export-a-worksheet-to-xlsx
          /* make the worksheet */
          const ws = XLSX.utils.json_to_sheet(arrData);
          /* add to workbook */
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          /* generate an XLSX file */
          XLSX.writeFile(wb, "product_list.xlsx");
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  watch: {},
};
</script>
