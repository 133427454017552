<template>
  <!-- <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <th>Name</th>
        <th>SKU</th>
        <th>Created</th>
        <th>Retail Price</th>
        <th>Promo Price</th>
        <th>Variance</th>
        <th colspan="2">Active</th>
      </thead>
      <tbody>
        <tr v-if="model.length == 0">
          <td colspan="9" align="center">No data in this list</td>
        </tr>
        <tr v-else v-for="(m, i) in model" :key="i">
          <td>
            {{ m.name }}
            <font-awesome title="Souvenir" icon="tags" size="sm" class="mr-1" v-if="!m.status_purchaseable" />
            <template v-if="m.status_stock == false">
              <div class="badge badge-warning">PO</div>
            </template>
          </td>
          <td>
            {{ m.sku }}
          </td>
          <td>
            {{ formatDate(m.created_date) }}
          </td>
          <td>
            {{ formatPrice(m.price) }}
          </td>
          <td>
            {{ formatPrice(m.promo_price) }}
          </td>
          <td>
            <router-link :to="'/manage-product-variance/' + m.id" class="btn btn-wz-variance">See Variance</router-link>
          </td>
          <td>
            <b-form-checkbox switch size="lg" v-model="m.is_active" @change="setActive($event, m)"></b-form-checkbox>
          </td>
          <td class="td-dropdown">
            <b-dropdown variant="link" toggle-class="p-0 text-black" right no-caret>
              <template #button-content>
                <font-awesome icon="ellipsis-v" />
              </template>
              <b-dropdown-item :to="'/edit-product/' + m.id"><b>EDIT</b></b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-delete-product @click="$emit('deleteProduct', m)"><b>DELETE</b></b-dropdown-item>
              <b-dropdown-item :to="'/detail-product/' + m.id"><b>VIEW</b></b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->
  <MyTable
    ref="myTable"
    :fields="table.fields"
    :items="table.items"
    :busy="table.isLoading"
    :selected="table.selected"
    :selectable="table.selectable"
    :sortBy="table.sortBy"
    :sortDesc="table.sortDesc"
    :currentPage="table.currentPage"
    :perPage="table.perPage"
    :totalRows="table.totalRows"
    @onSelected="onSelected"
    @onChangeCurrentPage="onChangeCurrentPage"
    @onRowClicked="table.currentSelectedRow = $event"
    @onHeadClicked="onHeadClicked"
    @onChangePerPage="onChangePerPage"
    :defaultValue="model"
  >
    <template #header>
      <div class="border rounded mt-1 p-1">
        <small v-for="(selection, i) in model" :key="i" class="badge badge-light border mr-1 mb-1">
          {{ selection.name }} <fawesome-pro variant="fal" icon="times" class="cursor-pointer" @click="deleteSelected(i)" />
        </small>
        <div>
          <button type="button" class="btn btn-success btn-sm" @click="$emit('bulkEdit', model.map(el => parseInt(el.id)))" v-b-modal.modal-bulk-edit-product>Bulk Edit</button>
        </div>
      </div>
      <!-- <b-dropdown v-if="model.length" text="Actions" variant="success">
        <b-dropdown-item v-b-modal.modal-bulk-edit-product>Edit Selected</b-dropdown-item>
      </b-dropdown> -->
    </template>
    <template #product_images="{ item }">
      <b-img-lazy :src="item.product_images[0].thumbnail_image_url" :height="96" />
    </template>
    <template #name="{ item }">
      {{ item.name }}
      <font-awesome title="Souvenir" icon="tags" size="sm" class="mr-1" v-if="!item.status_purchaseable" />
      <div v-if="!item.status_stock" class="badge badge-warning">PO</div>
    </template>
    <template #created_date="{ item }">
      {{ formatDate(item.created_date) }}
    </template>
    <template #price="{ item }">
      {{ formatPrice(item.price) }}
    </template>
    <template #promo_price="{ item }">
      {{ formatPrice(item.promo_price) }}
    </template>
    <template #id="{ item }">
      <router-link :to="'/manage-product-variance/' + item.id" class="btn btn-wz-variance">See Variance</router-link>
    </template>
    <template #stock="{ item }">
      {{ formatPrice(item.stock) }}
    </template>
    <template #is_active="{ item }">
      <b-form-checkbox switch size="lg" v-model="item.is_active" @change="setActive($event, item)"></b-form-checkbox>
    </template>
    <template #actions="{ item }">
      <b-dropdown variant="link" toggle-class="p-0 text-black" right no-caret>
        <template #button-content>
          <font-awesome icon="ellipsis-v" />
        </template>
        <b-dropdown-item :to="'/edit-product/' + item.id"><b>EDIT</b></b-dropdown-item>
        <b-dropdown-item v-b-modal.modal-delete-product @click="$emit('deleteProduct', item)"><b>DELETE</b></b-dropdown-item>
        <b-dropdown-item :to="'/detail-product/' + item.id"><b>VIEW</b></b-dropdown-item>
      </b-dropdown>
    </template>
  </MyTable>
</template>
<script>
import MyTable from "@/components/shared/MyTable";
import { toaster } from "@/_helpers";
import moment from "moment";

export default {
  name: "ProductListTable",
  props: ["value", "list"],
  components: {
    MyTable,
  },
  data() {
    return {
      oldSelected: [],
      table: {
        currentPage: this.list.page,
        perPage: this.list.limit,
        totalRows: this.list.total,
        items: this.list.items,
        fields: [
          { key: "selected", sortable: false },
          { key: "product_images", label: "Image", custom_column: true },
          { key: "name", label: "Name", custom_column: true },
          { key: "sku", label: "SKU" },
          { key: "created_date", label: "Created", custom_column: true },
          { key: "price", label: "Retail Price", custom_column: true },
          { key: "promo_price", label: "Promo Price", custom_column: true },
          { key: "id", label: "Variance", custom_column: true },
          { key: "stock", label: "Stock", custom_column: true },
          { key: "is_active", label: "Active", custom_column: true },
          { key: "actions", label: "", custom_column: true, tdClass: 'td-dropdown' },
        ],
        selected: this.list.selected,
        selectable: true,
        // sortBy: "id",
        // sortDesc: false,
        currentSelectedRow: null,
        isLoading: this.list.busy ?? false,
      },
    }
  },
  computed: {
    model() {
      return this.value || [];
    },
  },
  methods: {
    deleteSelected(index) {
      // this.table.isLoading = true
      this.table.selected.splice(this.table.selected.findIndex(el => el.id == this.model[index].id), 1)
      this.model.splice(index, 1)
      // setTimeout(() => {
      //   this.table.isLoading = false
      // }, 1);
    },
    //* Add or remove product list (event emitter for 'value' props)
    manageSingleSelectedProduct(tableSelected) {
      const selectedRow = this.table.currentSelectedRow;
      if (!selectedRow) return;

      let selectedProduct = this.model; //* Assign selected product data from parent component

      const found = tableSelected.find((el) => el.id == selectedRow.id);
      //* If the 'item' exist on Array 'selectedProduct', then add / merge the 'item' to 'selectedProduct'
      if (found) selectedProduct = [...this.model, selectedRow];
      //* If the 'item' not exist on Array 'selectedProduct' (aka Removed), then remove the 'item' from 'selectedProduct'
      else
        selectedProduct.splice(
          selectedProduct.findIndex((el) => el.id == selectedRow.id),
          1
        );

      this.$emit("input", selectedProduct);
      this.table.currentSelectedRow = null; //* Reset current selected row after emit selected product data to parent component (to prevent duplicate on page change)
    },
    manageMultipleSelectedProduct(tableSelected) {
      let selectedProduct = this.model; //* Assign selected product data from parent component
      //? Add all current selected table to parent v-model
      if (tableSelected.length > 0) {
        //* Prevent Duplicate on tableSelected.filter when merge model (selected product v-model from parent)
        const result = [
          ...selectedProduct,
          ...tableSelected.filter(
            (el) => !selectedProduct.find((rel) => rel.id == el.id)
          ),
        ];
        this.$emit("input", result);
      }
      //? Remove all current selected table from parent v-model
      else {
        const result = selectedProduct.filter(
          (el) => !this.oldSelected.find((rel) => rel.id == el.id)
        );
        this.$emit("input", result);
      }
    },
    onSelected(event) {
      this.table.selected = event
      this.manageSingleSelectedProduct(event);
    },
    onHeadClicked(key) {
      if (key != "selected") return;
      this.manageMultipleSelectedProduct(this.table.selected);
    },
    onChangeCurrentPage(event) {
      this.$emit('changeCurrentPage', event)
    },
    onChangePerPage(event) {
      // let oldSelected = []
      // for (let i = 0; i < this.oldSelected.flat(1).length; i += this.table.perPage)
      //   oldSelected.push(this.oldSelected.flat(1).slice(i, i + this.table.perPage))

      // this.oldSelected = oldSelected
      this.$emit('changePerPage', event)
    },
    async setActive(event, product) {
      try {
        const res = await this.$api.product.setActive(event, product.id)
        if (res.status === 200) {
          toaster.make(res.data.message, 'success')
          this.$emit('changeActive')
        } else {
          toaster.make(res.data.message, 'danger')
          product.is_active = !event
        }
      } catch (e) {
        console.error(e);
        product.is_active = !event
      }
    },
    formatDate(value) {
      return value ? moment(String(value)).format('YYYY-MM-DD HH:mm:ss').toString() : value
    },
    formatPrice(value, decimal = 0) {
      return value ? value.toLocaleString('id', {minimumFractionDigits: decimal}) : value
    },
  },
  watch: {
    "list.page"(newVal) {
      this.table.currentPage = newVal
    },
    "list.items"(newVal) {
      this.table.items = newVal
    },
    "list.limit"(newVal) {
      this.table.perPage = newVal
    },
    // "list.selected"(newVal, oldVal) {
    //   this.oldSelected = oldVal
    // },
    "table.selected"(newVal, oldVal) {
      this.oldSelected = oldVal;
    },
    "list.total"(newVal) {
      this.table.totalRows = newVal
    },
    "list.busy"(newVal) {
      this.table.isLoading = newVal
    }
  }
}
</script>
<style scoped>
thead th, td {
  vertical-align: middle;
}
tbody {
  font-size: .865rem;
  white-space: nowrap;
}
tbody p {
  line-height: 22px;
  margin-bottom: .5rem;
}
.td-dropdown {
  padding: 1rem .25rem;
}
@media (min-width: 992px) {
  tbody {
    white-space: normal;
  }
  .table-responsive {
    overflow: initial;
  }
}
.mw-400px {
  max-width: 400px;
  text-align: justify;
  white-space: normal;
}
</style>
